<template>

    <vue-good-table
        ref="table"
        class="mt-1"
        :columns="columns"
        :rows="data"
        :styleClass="`vgt-table striped bordered ${condensed ? 'condensed' : ''}`"
        :row-style-class="rowStyleClassFn"
        :pagination-options="{
            enabled: true,
            perPage: pageLengthData
        }"
    >
        <div class="text-center" slot="emptystate">
            {{$t('table.no_data_to_display')}}
        </div>
        <template slot="table-row" slot-scope="props">

            <slot v-bind="props">
                <span class="align-content-center">
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </slot>

        </template>

        <template
            slot="pagination-bottom"
            slot-scope="props"
        >

            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                        {{ (($refs.table) ? $refs.table.currentPage : 0) * pageLengthData - (pageLengthData - 1) }} - {{ props.total - (($refs.table) ? $refs.table.currentPage : 0) * pageLengthData > 0 ? (($refs.table) ? $refs.table.currentPage : 0) * pageLengthData : props.total }} {{$t('table.of')}} {{ props.total }}
                    </span>

                    <b-dropdown
                        variant="none"
                        no-caret>

                        <template v-slot:button-content>
                            <span>({{pageLengthData}} {{$t('table.on_page')}}) </span>
                            <feather-icon
                                icon="ChevronDownIcon"
                                size="16"
                                class="text-body align-middle mr-25"
                            />
                        </template>

                        <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">
                            {{ length }}
                        </b-dropdown-item>

                    </b-dropdown>
                </div>
                <div>
                    <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLengthData"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </div>
            </div>

        </template>

    </vue-good-table>

</template>
<script>

    import { VueGoodTable } from 'vue-good-table'
    import { BPagination, BDropdown, BDropdownItem  } from 'bootstrap-vue'

    export default {
        components: {
            BPagination,
            BDropdown,
            VueGoodTable,
            BDropdownItem
        },
        props: {
            pageLength: {
                type: Number,
                default: 10
            },
            data: {
                type: Array,
                required: true
            },
            columns: {
                type: Array,
                required: true
            },
            lengths: {
                type: Array,
                required: false,
                default: () => {
                    return [
                        '10',
                        '20',
                        '50',
                        '100'
                    ]
                }
            },
            condensed: {
                type: Boolean,
                default: false
            },
            enableRowMarking: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                pageLengthData: 10
            }
        },
        methods: {
            setPageLength(length) {
                this.pageLengthData = Number(length)
                this.$refs.table.perPageChanged({currentPerPage:this.pageLengthData})
            },
            rowStyleClassFn(row) {
                if (!this.enableRowMarking) return ''
                return row.disabled ? 'markDisabled' : ''
            }
        },
        created() {
            this.pageLengthData = this.lengths[0]
        },
        mounted() {
            this.$refs.table.perPageChanged({currentPerPage:this.pageLength})
            this.pageLengthData = this.pageLength
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
    [dir] body.dark-layout .vgt-wrap .vgt-table th, [dir] body.dark-layout .vgt-wrap .vgt-table td, [dir] body.dark-layout .vgt-wrap .vgt-table tr{
        background-color: transparent;
    }

    .dark-layout .markDisabled {
        background-color: #e74c3c50!important;
    }

    .markDisabled{
        background-color: #ffd6d6!important;
    }
</style>
